.Home{
    margin: 0 3.5rem 0 21.5rem;
    text-align: left;
    overflow: 'scroll';
    overflow-y: auto;
}

#H-wolf{
    border-radius: 50%;
    background: var(--color-d);
    width: 140rem;
    height: 140rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: -31rem;
    left: 35rem;
    z-index: -2;
    animation: slide-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

#H-wolf > div{
    border-radius: 50%;
    background: var(--color-d1);
    width: 125rem;
    height: 125rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

#H-wolf > div > div{
    border-radius: 50%;
    background: var(--color-d2);
    width: 110rem;
    height: 110rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

#H-wolf > div > div > div{
    border-radius: 50%;
    background: var(--color-d3);
    width: 95rem;
    height: 95rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

#H-wolf > div > div > div > div{
    border-radius: 50%;
    background: var(--color-d4);
    width: 80rem;
    height: 80rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

#H-wolf > div > div > div > div > div{
    border-radius: 50%;
    background: var(--color-d5);
    width: 65rem;
    height: 65rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

#H-img{
    position: absolute;
    top: 13rem;
    left: 81rem;
    width: 50rem;
    height: 50rem;
    border-radius: 50%;
    z-index: -1;
    opacity: 0.8;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
.H-img{
    animation: slide-scale-i 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.H-img.active{
    animation: slide-scale-in .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.H-title{
    animation: slide-right 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    width: 90%;
}
.H-title.active{
    animation: slide-right-n .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.H-sub{
    width: 30rem;
    font-size: 1.4rem;
    letter-spacing: 0.3rem;
    height: 10rem;
    color: var(--color-danger);
    background: transparent;
    border: none;
    cursor: pointer;
    transition: all .5s ease;
    animation: slide-right 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    z-index: 1;
}
.H-sub.off{
    animation: slide-right-n .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.H-sub:hover{
    color: var(--color-danger-variant);
}
.H-sub.active{
    font-size: 6rem;
    font-weight: 700;
    letter-spacing: 1rem;
    margin-left: 35rem;
    color: var(--color-danger-variant);
}

.H-sub > .s1{
    width: 23rem;
    height: 0.5rem;
    margin: 0 0 0.3rem 0;
    border-radius: 0.5rem;
    background: var(--color-danger);
    transition: all 500ms ease;
}
.H-sub:hover > .s1{
    background: var(--color-danger-variant);
}
.H-sub > .s1.active{
    transform: translateX(-12rem) scaleX(8);
    background: var(--color-danger-variant);
}

.H-sub > .s2{
    width: 23rem;
    height: 0.5rem;
    margin: 0.3rem 0 0 6.4rem;
    border-radius: 0.5rem;
    background: var(--color-danger);
    transition: all 500ms ease;
}
.H-sub:hover > .s2{
    background: var(--color-danger-variant);
}
.H-sub > .s2.active{
    transform: translateX(23rem) scaleX(6);
    background: var(--color-danger-variant);
}

.H-main{
    font-size: 1.5vw;
    font-weight: 400;
    letter-spacing: 0.5rem;
    opacity: .8;
    animation: slide-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.H-main.active{
    animation: slide-top-n .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.Home > main{
    height: 100vh;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
}

.Home > main > section{
    height: 100vh;
    padding-top: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    scroll-snap-align: start;
    overflow: hidden;
}

.Home > main > section > div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
}

.Home > main > section > div > div{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 80%;
    margin: auto 0;
}

.number{
    font-size: 20rem;
    font-weight: 600;
    width: 26rem;
    color: var(--color-white);
    animation: slide-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    z-index: 1;
}
.number.active{
    animation: slide-left-n .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/*Section 1*/
.Home > main > .one > .H-sub{
    margin-top: 3rem;
    width: 19rem;
}

.Home > main > .one > .H-sub > .s1, .Home > main > .one > .H-sub > .s2{
    width: 12.5rem;
}

.Home > main > .one > #CH-main{
    margin-bottom: 5rem;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}

/*Section 2*/
.Home > main > .two > div > div > div{
    text-align: center;
}

.Home > main > .two > div > div > div > div > img{
    animation: slide-scale 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.Home > main > .two > div > div > div > div > img:hover{
    opacity: 1;
    transform: scale(1.1);
    filter: none;
}
.Home > main > .two > div > div > div > div > img.active{
    animation: slide-scale-n .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/*Section 3*/
.Home > main > .three{
    position: relative;
}

.Home > main > .three > div > div > p{
    width: 60%;
}

.Home > main > .three > div > div > img{
    height: 78vh;
    border-radius: 2rem;
    position: absolute;
    bottom: 7vh;
    right: 0;
    filter: brightness(0.7);
    animation: slide-translate-m 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    z-index: -1;
}
.Home > main > .three > div > div > img.active{  
    animation: slide-translate-mn .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/*Section 4*/
.Home > main > .four > div > div{
    width: 100%;
}

.Home > main > .four > div > div > a{
    display: block;
    color: var(--color-white);
    width: 50%;
    margin-top: 1rem;
    font-size: 1.8rem;
    font-weight: 400;
    letter-spacing: 0.5rem;
    transition: all 500ms ease;
    animation: slide-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.Home > main > .four > div > div > a:hover{
    opacity: 0.7;
}
.Home > main > .four > div > div > a.active{
    animation: slide-top-n .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.Home > main > .four > div > div > .container{
    gap: 3rem;
    margin: 5rem 0 0 0;
    animation: slide-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.Home > main > .four > div > div > .container.active{
    animation: slide-top-n .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.Home > main > .four > div > div > .container > a{
    display: inline-block;
    margin: 2rem;
}

.Home > main > .four > div > div > .container > a > ion-icon{
    transition: all  350ms ease;
}
.Home > main > .four > div > div > .container > a > ion-icon:hover, .Home > main > .four > div > div > .container > a > img:hover{
    opacity: 0.7;
    transform: scale(1.5);
}

.Home > main > .four > div > div > .container > a > img{
    width: 3rem;
    height: 3rem;
    border-radius: 0.3rem;
    filter: grayscale(1);
    background: var(--color-white);
    transition: all 350ms ease;
}

footer{
    height: 5rem;
    text-align: right;
    font-size: 1.4rem;
    letter-spacing: 0.2rem;
}

footer > p > span{
    color: var(--color-danger);
    font-weight: 600;
}

@keyframes slide-right{
    0% {
        transform: translateX(-10rem);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes slide-right-n{
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-10rem);
    }
}

@keyframes slide-left{
    0% {
        transform: translateX(30rem);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slide-left-n{
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(30rem);
    }
}

@keyframes slide-top{
    0% {
        transform: translateY(5rem);
    }
    100% {
        transform: translateY(0);
    }
}
@keyframes slide-top-n{
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(5rem);
    }
}

@keyframes slide-scale-i{
    0% {
        transform: scale(0) translateX(30rem);
    }
    100% {
        transform: scale(1) translateX(0);
    }
}

@keyframes slide-scale-in{
    0% {
        transform: scale(1) translateX(0);
    }
    100% {
        transform: scale(0) translateX(30rem);
    }
}

@keyframes slide-scale{
    0% {
        transform: scale(0.25);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes slide-scale-n{
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.25);
    }
}

@keyframes slide-translate-m{
    0% {
        transform: translateX(-9rem) translateY(-4rem) scale(1.3);
    }
    100% {
        transform: translateX(0) translateY(0) scale(1);
    }
}

@keyframes slide-translate-mn{
    0% {
        transform: translateX(0) translateY(0) scale(1);
    }
    100% {
        transform: translateX(-2rem) translateY(-7rem) scale(1.1);
    }
}


@media only screen and (max-width: 960px){
    .Home{
        margin: 0;
        padding: 0 1.5rem 1.5rem 1.5rem;
    }

    #H-wolf{
        display: none;
    }

    #H-img{
        left: 0;
        width: 90%;
        margin: 0 5%;
        height: auto;
        opacity: 0.2;  
    }

    .Home > main > section{
        padding: 12rem 0 20rem 0;
    }
    .Home > main > .one{
        padding-top: 20rem;
    }
    .Home > main > .two, .Home > main > .four{
        padding-bottom: 12rem;
    }
    
    .H-title{
        text-align: center;
        font-size: 10vw;
        margin: 0;
        letter-spacing: 1rem;
        flex: 0.75;
    }

    .Home > main > .one > .H-sub{
        margin: 0;
        flex: 2.5;
    }

    .Home > main > .one > #CH-main{
        margin: 0;
        flex: 1;
    }

    .H-main{
        font-size: 3.5vw;
    }

    .H-sub.active{
        font-size: 4rem;
    }
    .H-sub > .s1.active{
        transform: translateX(7Rem) translateY(-2rem) scaleX(2.4);
    }
    .H-sub > .s2.active{
        transform: translateX(4rem) translateY(1rem) scaleX(2.7);
    }
    
    .Home > main > .two > .H-title{
        margin-bottom: 5rem;
    }

    .Home > main > .two > div > div > div > div > img{
        height: 12rem;
        margin: 0;
    }

    .Home > main > .two > div > div > div > div > p{
        display: none;
    }

    .Home > main > .three > .H-sub{
        margin: 2rem 0;
    }

    .Home > main > .three > div > div > p{
        width: 100%;
    }

    .Home > main > .three > div > div > img{
        height: auto;
        width: 100%;
        position: static;
        margin-top: 2rem;
    }

    .number{
        display: none;
    }

    .Home > main > .four > div > div > a{
        font-size: 1.2rem;
    }

    footer{
        text-align: left;
        font-size: 3vw;
    }
}