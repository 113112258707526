.Theme{
    flex: 1;
    margin: 0 1rem;
    color: var(--color-white);
    background: transparent;
    text-align: center;
    cursor: pointer;
    transition: all 350ms ease;
}
.Theme:hover{
    opacity: 0.7;
}

.Theme > span{
    font-size: 3rem;
    font-weight: 400;
    color: var(--color-white);
    vertical-align: middle;
}