@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans&family=Poppins:wght@300;400;500;600;700;800&display=swap');

/* ------------- ROOT VARIABLES ------------- */
:root{
    --color-primary: #2c54cc;
    --color-primary-variant: #163590;
    --color-danger: #be3039;
    --color-danger-variant: #921e26;
    --color-sucess: #22e2a2;
    --color-sucess-variant: #22e2a2;
    --color-warning: #ffbb55;
    --color-warning-variant: #dd9f41;
    --color-white: #e7e7e7;
    --color-white-variant: rgb(145, 143, 143);
    --color-black: rgb(41, 41, 41);
    --color-black-variant: rgb(58, 58, 58);
    --color-background: linear-gradient(90deg, rgba(5,22,40,1) 0%, rgba(2,11,20,1) 10%, rgba(1,6,10,1) 25%, rgba(0,1,3,1) 100%);
    --filter: grayscale(1);
    --box-shadow: inset 0 -1px 0 var(--color-dark-light);
    --color-header: linear-gradient(90deg, rgb(136,16,25) 0%, rgb(99,10,16) 50%, rgb(60,5,9) 100%);
    --color-d: rgba(200, 200, 200, .1);
    --color-d1: rgba(180, 180, 180, .1);
    --color-d2: rgba(160, 160, 160, .1);
    --color-d3: rgba(140, 140, 140, .2);
    --color-d4: rgba(120, 120, 120, .3);
    --color-d5: rgba(50, 50, 50, .3);
    --test: 1px solid red;
}

.dark-theme-variables{
    --color-white: rgb(23, 23, 23);
    --color-white-variant: rgb(58, 58, 58);
    --color-black: #eee;
    --color-black-variant: rgb(219, 219, 219);
    --color-background: linear-gradient(90deg, rgb(146, 231, 250) 0%, rgb(110,186,203) 53%, rgb(84, 153, 169) 100%);
    --color-danger: #126c4e;
    --color-danger-variant: #09583d;
    --filter: grayscale(1) brightness(0);
    --color-header: linear-gradient(90deg, rgb(94, 199, 178) 0%, rgb(70,159,141) 50%, rgb(49, 125, 110) 100%);
    --color-d: rgba(50, 50, 50, .1);
    --color-d1: rgba(100, 100, 100, .2);
    --color-d2: rgba(130, 130, 130, .3);
    --color-d3: rgba(170, 170, 170, .4);
    --color-d4: rgba(200, 200, 200, .5);
    --color-d5: rgba(250, 250, 250, .5);
}

*{
    margin: 0;
    padding: 0;
    outline: 0;
    appearance: none;
    border: 0;
    text-decoration: none;
    box-sizing: border-box;
}

html{
    font-size: 10px;
}

body{
    width: 100vw;
    height: 100vh;
    font-family: 'Poppins', sans-serif;
    background: rgb(5,22,40);
    background: var(--color-background);
    user-select: auto;
    overflow: auto;
    font-size: 1.6rem;
    font-weight: 300;
    color: var(--color-white);
    touch-action: none;
}
::-webkit-scrollbar {
    display: none;
}

.App {
    text-align: center;
    height: 100vh;
}

h1{
    color: var(--color-white);
    font-size: 8vw;
    margin-left: -0.5rem;
    font-weight: 500;
    letter-spacing: 1rem;
    z-index: 1;
}

h2{
    color: var(--color-white);
    font-size: 7vw;
    font-weight: 500;
    letter-spacing: 1.5rem;
    z-index: 1;
}

h3{
    font-size: 2vw;
}

h4, h5{
    font-size: 2rem;
}

ion-icon {
    color: var(--color-white);
}

.B-return{
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 4rem;
    color: var(--color-white);
    cursor: pointer;
    background: transparent;
    transition: all 350ms ease;
}
.B-return:hover{
    opacity: 0.7;
}

.B-return > span{
    font-size: 3.2rem;
}

.B-return > p{
    letter-spacing: 0.6rem;
    font-size: 1.6rem;
    font-weight: 600;
    background: transparent;
}