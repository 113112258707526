.WorksD{
    margin: 0;
    text-align: left;
}

.WorksD > main{
    height: 100vh;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
}

.WorksD > main > section{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    scroll-snap-align: start;
}

.WorksD > main > .two{
    align-items: flex-start;
}

.WorksD > main > .two > img{
    position: absolute;
    top: 15%;
    left: 30%;
    filter: grayscale(0) blur(3px);
    opacity: 0.2;
    z-index: -1;
    -webkit-animation: slide-scale-dd 750ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-scale-dd 750ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.WorksD > main > .three{
    background-image: url(../../images/background_pokemon.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.WorksD > main > .three > img{
    width: 40vh;
}

.WorksD > main > .four{
    padding: 0;
}


@keyframes slide-scale-dd{
    0% {
        transform: translateX(-25.5rem) translateY(8rem) scale(0.4);
    }
    100% {
        transform: translateX(0) translateY(0) scale(1);
    }
}


@media only screen and (max-width: 960px){
    .WorksD{
        margin: 0;
        padding: 0m;
    }

    .WorksD > main > .three > img{
        display: none;
    }
}