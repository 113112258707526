.AboutMe{
    margin: 0 3.5rem 0 21.5rem;
    text-align: left;
}

.AboutMe > main{
    height: 100vh;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
}

.AboutMe > main > section{
    scroll-snap-align: start;
    overflow: hidden;
    height: 100vh;
}

.AboutMe > main > .one{
    padding-top: 12rem;
    position: relative;
}

.AboutMe > main > .one > .H-title{
    -webkit-animation: slide-bottom 750ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-bottom 750ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.AboutMe > main > .one > .H-sub{
    margin-top: 2rem;
    width: 25rem;
    -webkit-animation: slide-bottom 1000ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-bottom 1000ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.AboutMe > main > .one > .H-sub > .s1, .AboutMe > main > .one > .H-sub > .s2{
    transform: scaleX(1.3) translateX(1.5rem);
    width: 14.5rem;
}

.AboutMe > main > .one > .H-main{
    margin-top: 6rem;
    width: 38%;
    -webkit-animation: slide-bottom 1250ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-bottom 1250ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.AboutMe > main > .one > .A-img{
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0rem;
    z-index: -1;
    filter: brightness(0.6); 
    -webkit-animation: slide-scale-p 750ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-scale-p 750ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.AboutMe > main > .two, .AboutMe > main > .three, .AboutMe > main > .four{
    padding-top: 15rem;
}

.w-container{
    height: 60vh;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.w-img{
    width: 40rem;
    border-radius: 50%;
    opacity: 1;
    transition: all 350ms ease;
}

.w-info{
    width: 40rem;
    display: none;
    text-align: center;
    position: relative;
    top: 0;
    transition: all 350ms ease;
}

.w-info > h4{
    font-size: 5rem;
    letter-spacing: 1.5rem;
    line-height: 5rem;
    font-weight: 300;
    margin-bottom: 4rem;
}

.w-info > i{
    font-size: 2rem;
    letter-spacing: 1rem;
    font-weight: 300;
}

.w-container > div:hover > .w-img{
    opacity: 0.2;
}

.w-container > div:hover > .w-info{
    display: grid;
    top: -33rem;
}

.w-container > .w-message{
    cursor: text;
    width: 75%;
    text-align: center;
}

.w-container > div > p{
    width: 70%;
    font-size: 2rem;
    font-weight: 300;
    letter-spacing: 0.2rem;
    margin: 4.5rem auto;
    text-align: center;
}

.AboutMe > main > .three > .row{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.AboutMe > main > .three > div > .container{
    width: 46%;
}

.SL-img, .SF-img, .SD-img, .SS-img, .EL-img, .ES-img{
    width: 5rem;
    height: 5rem;
    margin: 0.7rem;
    padding: 0rem;
    border-radius: 5px;
    transition: all 350ms ease;
}
.SL-img:hover, .SF-img:hover, .SD-img:hover, .SS-img:hover, .EL-img:hover, .ES-img:hover{
    opacity: 0.7;
    transform: scale(1.3);
}

.diamond{
    background: transparent;
}

.diamond.active{
    background: #00ffe5;
}

.diamond:hover{
    background: #00ffe5;
}

.gold{
    background: transparent;
}

.gold.active{
    background: #ffb700;
}

.gold:hover{
    background: #ffb700;
}

.silver{
    background: transparent;
}

.silver.active{
    background: #C8C8C8;
}

.silver:hover{
    background: #C8C8C8;
}

.bronze{
    background: transparent;
}

.bronze.active{
    background: #E05B2E;
}

.bronze:hover{
    background: #E05B2E;
}

.leyenda{
    width: 1.2rem;
    height: 50rem;
}

.leyenda > div{
    width: 100%;
    height: 25%;
    color: black;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 350ms ease;
}
.leyenda > div:hover{
    opacity: 0.7;
}
.leyenda > div.active{
    color: var(--color-white);
    background: transparent;
}

.leyenda > div > p{
    font-weight: 700;
    margin: auto;
    font-size: 0.8rem;
    transform: rotate(-90deg);
}

.leyenda-d{
    border-radius: 0.5rem 0.5rem 0 0;
    background: #00ffe5;
}
.leyenda-d.active{
    border: 1px solid #00ffe5;
}

.leyenda-g{
    background: #ffb700;
}
.leyenda-g.active{
    border: 1px solid #ffb700;
}

.leyenda-s{
    background: #C8C8C8;
}
.leyenda-s.active{
    border: 1px solid #C8C8C8;
}

.leyenda-b{
    border-radius: 0 0 0.5rem 0.5rem;
    background: #E05B2E;
}
.leyenda-b.active{
    border: 1px solid #E05B2E;
}

.AboutMe > main > .four{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.AboutMe > main > .four > div{
    text-align: center;
    margin: 1rem 0 9rem 0;
}

.E-container{
    width: 36rem;
    height: 31rem;
    padding: 2.5rem 0;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

.E-container > a{
    color: var(--color-white);
}

.E-container-C{
    margin: 0 1.6rem;
}

.E-logo{
    width: 20rem;
    opacity: 1;
    transition: all 500ms ease;
}

.E-logo-C{
    width: 16.5rem;
    transition: all 500ms ease;
}

.E-info{
    display: none;
}

.E-info > h4{
    font-size: 1.5rem;
}

.E-info > i{
    font-size: 1.4rem;
    color: var(--color-white-variant);
    margin: 0;
}

.E-info > ul{
    font-size: 1.4rem;
    margin: 1rem 0 0 10rem;
    text-align: left;
    list-style: circle;
}

.E-container:hover{
    opacity: 1;
}

.E-container:hover > a > .E-logo{
    opacity: 0.5;
    width: 6.6rem;
}

.E-container:hover > a > .E-logo-C{
    opacity: 0.5;
    width: 5.5rem;
}

.E-container:hover > a > .E-info{
    display: grid;
}

.AboutMe > main > .four > footer{
    margin-bottom: 3rem;
}


@keyframes slide-bottom {
    0% {
        transform: translateY(-8.2rem);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes slide-scale-p {
    0% {
        transform: translateX(9rem) translateY(2rem) scale(0.8);
    }
    100% {
        transform: translateX(0) translateY(0) scale(1);
    }
}


@media only screen and (max-width: 960px){
    .AboutMe{
        margin: 0;
        padding: 0 1.5rem 1.5rem 1.5rem;
    }

    .AboutMe > main > .one > .A-img{
        top: -63.5rem;
        left: 0;
        height: auto;
        width: 100%;
    }

    .AboutMe > main > .one > .H-main{
        width: 100%;
    }

    .w-container{
        margin: 2rem 0;
        flex-direction: column;
        align-items: center;
        height: 50rem;
    }
    
    .w-container > div{
        overflow: hidden;
        height: 50rem;
    }

    .w-img{
        width: 25rem;
    }
    
    .w-info{
        width: 25rem;
    }
    
    .w-info > h4{
        font-size: 3rem;
        letter-spacing: 1rem;
        line-height: 3rem;
        margin-bottom: 3rem;
    }
    
    .w-info > i{
        font-size: 1rem;
        letter-spacing: 1rem;
    }

    .w-container > div:hover > .w-info{
        top: -22rem;
    }
    
    .w-container > .w-message{
        width: 100%;
    }
    
    .w-container > .w-message > p{
        width: 100%;
        font-size: 1.2rem;
        margin: 2rem auto;
    }

    .AboutMe > main > .two{
        padding-top: 12rem;
    }

    .AboutMe > main > .three, .AboutMe > main > .four{
        padding-top: 8rem;
    }

    .AboutMe > main > .two > h3, .AboutMe > main > .three > h3{
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }

    .AboutMe > main > .three > .row{
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    .AboutMe > main > .three > .row > .container{
        width: 100%;
    }
    
    .AboutMe > main > .three > .row > .container > h4{
        font-size: 1.3rem;;
    }

    .AboutMe > main > .three > .row > .container > div > div > p, .AboutMe > main > .three > .row > .container > div > div > div > p{
        font-size: 1rem;;
    }

    .SL-img, .SF-img, .SD-img, .SS-img, .EL-img, .ES-img{
        width: 3rem;
        height: 3rem;
        margin: 0.3rem;
        padding: 0;
    }
    
    .leyenda{
        height: 1.2rem;
        width: 100%;
        display: flex;
        margin: 2rem 0;
    }
    
    .leyenda > div{
        width: 25%;
        height: 100%;
    }

    .leyenda > div > p{
        transform: rotate(0deg);
    }

    .leyenda-d{
        border-radius: 0.5rem 0 0 0.5rem;
    }
    
    .leyenda-b{
        border-radius: 0 0.5rem 0.5rem 0;
    }

    .AboutMe > main > .four > div > .E-container{
        width: 100%;
        height: 18rem;
    }   
    
    .E-logo, .E-logo-C{
        height: 100%;
        width: auto;
        margin: 0;
    }
    
    .E-container:hover > a > .E-logo, .E-container:hover > a > .E-logo-C{
        height: 30%;
        width: auto;
    } 
    
    .E-info > h4{
        font-size: 1rem;
    }
    
    .E-info > h5{
        font-size: 1.3rem;
    }
    
    .E-info > i{
        font-size: 0.8rem;
    }
    
    .E-info > ul{
        font-size: 0.8rem;
        margin: 0.8rem 0 0 0;
        text-align: center;
        list-style: none;
    }
}