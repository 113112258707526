.Works{
    text-align: left;
}

.Works > main{
    height: 100vh;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
}

.Works > main > .one{
    padding: 12rem 3.5rem 0 21.5rem;
    scroll-snap-align: start;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Works > main > .one > footer{
    margin-bottom: 3rem;
}

.Works > main > .one > .H-title{
    z-index: 1;
    -webkit-animation: slide-bottom 750ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-bottom 750ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.W-container{
    margin: 2rem 22rem 3.3rem 0;
    display: flex;
    justify-content: space-between;
}

.W-container > div{
    cursor: pointer;
    text-align: center;
    width: 40rem;
    height: 20rem;
    margin: 1rem 0;
}

.W-container > div > img{
    width: 20rem;
    filter: var(--filter);
    transition: all 500ms ease;
}

.W-container > div:hover > img{
    filter: grayscale(0) blur(1px);
    transform: scale(1.1);
}

.W-container > div > .wd-img{
    animation: slide-scale-wd 750ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.W-container > div > .we-img{
    animation: slide-scale-we 750ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.W-container > div > p{
    font-size:  4rem;
    font-weight: 500;
    letter-spacing: 1rem;
    margin-left: 1rem;
    visibility: hidden;
    transition: all 350ms ease;
}

.W-container > div:hover > p{
    visibility: visible;
    transform: translateY(-13.6rem);
}

.Works > footer{
    margin-top: -3rem;
}

@keyframes slide-scale-we{
    0% {
        transform: translateX(-25.5rem) translateY(-8rem) scale(2.5);
    }
    100% {
        transform: translateX(0) translateY(0) scale(1);
    }
}

@keyframes slide-scale-we-r{
    0% {
        transform: scale(2.5);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes slide-scale-wd{
    0% {
        transform: translateX(25.5rem) translateY(-8rem) scale(2.5);
    }
    100% {
        transform: translateX(0) translateY(0) scale(1);
    }
}

@keyframes slide-scale-wd-r{
    0% {
        transform: scale(2.5);
    }
    100% {
        transform: scale(1);
    }
}

@media only screen and (max-width: 960px){
    .Works{
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .Works > main > .one{
        padding: 12rem 1.5rem 8rem 1.5rem;
    }

    .W-container{
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0;
    }
    
    .W-container > div{
        width: 40%;
        height: auto;
    }

    .W-container > div > img{
        height: auto;
        width: 100%;
    }
    
    .W-container > div > .wd-img{
        animation: slide-scale-wd-r 750ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    
    .W-container > div > .we-img{
        animation: slide-scale-we-r 750ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    
    .W-container > div > p{
        font-size:  80%;
    }
    
    .W-container > div:hover > p{
        transform: translateY(-9.6rem);
    }
}