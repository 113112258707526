.Header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3.5rem 3.5rem 2rem 3.5rem;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 2;
}

.Header > p{
    flex: 2;
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 0.5rem;
    text-align: left;
    cursor: pointer;
    transition: all 350ms ease;
    opacity: 1;
    z-index: 4;
}
.Header > p:hover{
    opacity: 0.7;
}

.Header > b{
    flex: 8;
}

.H-row{
    flex: 5;
    display: flex;
    justify-content: space-between;
}

.H-row > a, .H-row > .L-btn{
    flex: 1;
    margin: 0 1rem;
    font-size: 2rem;
    color: var(--color-white);
    background: transparent;
    text-align: center;
    cursor: pointer;
    transition: all 350ms ease;
}
.H-row > a:hover, .H-row > .L-btn:hover{
    opacity: 0.7;
}

.H-row > a > span, .H-row > .L-btn > span{
    font-size: 3rem;
    font-weight: 400;
    color: var(--color-white);
    vertical-align: middle;
}

.H-btn{
    flex: 1;
    display: flex;
    justify-content: left;
    align-items: flex-end;
    flex-direction: column;
    border: none;
    background: transparent;
    cursor: pointer;
    z-index: 4;
    transition: all 350ms ease;
}
.H-btn:hover{
    opacity: 0.7;
}

.H-btn > .s1{
    position: relative;
    top: 0.4rem;
    width: 5rem;
    height: 0.3rem;
    border-radius: 0.5rem;
    background: var(--color-white);
    transform-origin: top left;
    transition: all 350ms ease;
}
.H-btn > .s1.active{
    transform: translateX(0.8rem) translateY(-0.8rem) rotate(45deg);
}

.H-btn > .s2{
    position: relative;
    top: 2rem;
    width: 5rem;
    height: 0.35rem;
    border-radius: 0.5rem;
    background: var(--color-white);
    transform-origin: bottom left;
    transition: all 350ms ease;
}
.H-btn > .s2.active{
    transform: translateX(0.8rem) translateY(0.8rem) rotate(-45deg);
}

.menu{
    padding-top: 10vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: var(--color-header);
    z-index: 3;
}
.menu-fw{
    display: block;
    color: var(--color-white);
    -webkit-animation: slide-bottom-fw 350ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-bottom-fw 350ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.menu-bk{
    -webkit-animation: slide-bottom-bk 700ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-bottom-bk 700ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.menu-p{
    display: block;
    margin: 2.5rem auto;
    font-size: 9rem;
    color: var(--color-white);
    background: transparent;
    border: none;
    cursor: pointer;
    opacity: 1;
    transition: all 400ms ease;
}
.menu-p:hover{
    transform: scale(1.1);
    opacity: 0.8;
}

.menu > div{
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-a, .menu > div > .Theme{
    flex: 1;
    margin: 7vh 1rem;
    color: var(--color-white);
    background: transparent;
    text-align: center;
    cursor: pointer;
    transition: all 350ms ease;
}
.menu-a:hover, .menu > div > .Theme:hover{
    transform: scale(1.7);
    opacity: 0.7;
}

.menu-a > span, .menu > div > .Theme > span{
    font-size: 5vh;
    font-weight: 400;
    vertical-align: middle;
}

.menu-np, .menu-na, .menu-bk > div > .Theme > span{
    color: transparent;
    background: transparent;
    border: none;
    visibility: hidden;
}

@keyframes slide-bottom-fw{
    0% {
        -webkit-transform: translateY(-100vh);
        transform: translateY(-100vh);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slide-bottom-bk{
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-100vh);
        transform: translateY(-100vh);
    }
}


@media only screen and (max-width: 960px){
    .Header{
        padding: 3.5rem 1.5rem 2rem 1.5rem;
    }

    .Header > .H-row > a, .Header > .H-row > .Theme, .Header > .H-row > .L-btn{
        visibility: hidden;
        display: none;
    }

    .H-btn > .s1{
        top: -0.8rem;
    }
    
    .H-btn > .s2{
        top: 0.8rem;
    }

    .menu{ 
        padding-top: 15vh;
    }

    .menu-fw{
        -webkit-animation: slide-bottom-fw 350ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-bottom-fw 350ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    .menu-bk{
        -webkit-animation: slide-bottom-bk 700ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-bottom-bk 700ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    .menu-p{
        font-size: 13vw;
    }

    .menu > div{
        margin: 5vh auto;
        width: 80%;
    }

    .menu-a, .menu > div > .Theme{
        font-size: 6vh;
        width: 30%;
        height: 10vh;
        display: inline-block;
    }

    .menu-a > span, .menu > div > .Theme > span{
        display: inline-block;
        margin: 0;
    }
}