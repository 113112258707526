.Left{
    width: 5rem;
    height: 100vh;
    margin-left: 3.5rem;
    padding-top: 35vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    top: 0;
    left: 0;
    z-index: 1;
}

.Left > div > button{
    display: block;
    width: 2.5rem;
    height: 0.3rem;
    border: none;
    border-radius: 0.5rem;
    margin: 2.5rem 0;
    padding: 0;
    opacity: 1;
    background: var(--color-white);
    cursor: pointer;
    transition: all 350ms ease;
}
.Left > div > button:hover{
    opacity: 0.7;
    width: 5rem;
}
.Left > div > button.active{
    width: 5rem;
    background: var(--color-danger);
}

.L-scroll{
    transition: all 750ms ease;
}

.L-scroll > p{
    font-size: 1.3rem;
    writing-mode: vertical-lr;
    letter-spacing: 0.3rem;
}

.L-scroll > div{
    width: 0.3rem;
    height: 12rem;
    border-radius: 0.5rem;
    margin: 1rem 0 0 0.9rem;
    background: var(--color-white);
}

.L-scroll.active{
    transform: translateY(25rem);
}

@media only screen and (max-width: 960px){
    .Left{
        top: 44vh;
        left: 15vh;
        flex-direction: row;
    }

    .Left > .button{
        transform: rotateZ(-90deg);
    }

    .Left > div > button{
        margin: 2.5rem 2.5rem 2.5rem 0;
    }
    .Left > div > button:hover{
        margin: 2.5rem 0 2.5rem 0;
    }
    .Left > div > button.active{
        margin: 2.5rem 0 2.5rem 0;
    }

    .Left > .scroll{
        transform: translateY(-20vh) translateX(14vh) rotateZ(-180deg);
    }

    .Left > .one{
        display: none;
    }

    .L-scroll.active{
        transform: translateY(-40rem);
    }
}