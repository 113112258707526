.Contact{
    margin: 0 3.5rem 0 21.5rem;
    text-align: left;
}

.Contact > main{
    height: 100vh;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
}

.Contact > main > section{
    scroll-snap-align: start;
}

.Contact > main > .one{
    padding-top: 12rem;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Contact > main > .one > footer{
    margin-bottom: 3rem;
}

.Contact > main > .one > .H-title{
    z-index: 1;
    -webkit-animation: slide-bottom 750ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-bottom 750ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.Contact > main > .one > .H-sub{
    -webkit-animation: slide-bottom 1000ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-bottom 1000ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.Contact > main > .one > .H-sub{
    width: 22rem;
}
.Contact > main > .one > .H-sub.active{
    width: 4rem;
}

.Contact > main > .one > .H-sub > .s1, .Contact > main > .one > .H-sub > .s2{
    width: 15rem;
}
.Contact > main > .one > .H-sub > .s1.active, .Contact > main > .one > .H-sub > .s2.active{
    width: 12rem;
}

.Contact > main > .one > a{
    display: block;
    color: var(--color-white);
    opacity: 1;
    width: 100%;
    margin-top: 1rem;
    font-size: 1.8rem;
    font-weight: 400;
    letter-spacing: 0.5rem;
    transition: all 500ms ease;
    -webkit-animation: slide-bottom 750ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-bottom 750ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.Contact > main > .one > a:hover{
    opacity: 0.7;
}

.Contact > main > .one > div{
    margin-top: 4rem;
    -webkit-animation: opacity 1250ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: opacity 1250ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    margin-bottom: 7rem;
}

.Contact > main > .one > div > a > ion-icon{
    margin: 0 2rem 0 0;
    transition: all  350ms ease;
}
.Contact > main > .one > div > a > ion-icon:hover{
    opacity: 0.7;
    transform: scale(1.3);
}

.Contact > main > .one > div > a > img{
    margin: 0 2rem 0 0;
    width: 3rem;
    height: 3rem;
    background: var(--color-white);
    border-radius: 50%;
    transition: all 350ms ease;
}
.Contact > main > .one > div > a > img:hover{
    opacity: 0.7;
    transform: scale(1.3);
}

@-webkit-keyframes opacity {
    0%{
        opacity: 0;
    }
    20%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes opacity {
    0%{
        opacity: 0;
    }
    20%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 960px){
    .Contact{
        margin: 0;
        padding: 1.5rem;
    }

    .Contact > main > .one{
        padding-top: 12rem;
        padding-bottom: 6rem;
    }

    .Contact > main > .one > .H-title{
        margin: 5rem 0 0 0;
    }

    .Contact > main > .one > a{
        font-size: 1.5rem;
    }
}