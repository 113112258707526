.Music{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-top: 12rem;
    height: 100vh;
    color: var(--color-white);
}

.wrapper{
    padding: 3rem;
    border-radius: 2rem;
}

.details{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.track-art{
    margin: 2rem;
    height: 15rem;
    width: 15rem;
    border: 0.2rem solid #fff;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    -moz-box-shadow: 0 0.6rem 0.5rem #ccc;
    -webkit-box-shadow: 0 0.6rem 0.5rem #ccc;
    box-shadow: 0 0.6rem 0.5rem #ccc;
    -moz-border-radius: 19rem;
    -webkit-border-radius: 19rem;
    border-radius: 19rem;
}

.now-playing{
    font-size: 1rem;
}

.track-name{
    font-size: 2rem;
}

.track-artist{
    margin-top: 0.5rem;
    font-size: 1.2rem;
}

.buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2rem 0;
}

.buttons > div > span{
    font-size: 5rem;
}

.repeat-track, .random-track, .playpause-track, .prev-track, .next-track{
    padding: 1rem;
    cursor: pointer;
    transition: all 350ms ease;
}
.repeat-track:hover, .random-track:hover, .playpause-track:hover, .prev-track:hover, .next-track:hover{
    opacity: 0.7;
}

.randomActive{
    color: black;
}

.slider_container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.seek_slider, .volume_slider{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 0.5rem;
    background: #37ff91;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}
.seek_slider::-webkit-slider-thumb, .volume_slider::-webkit-slider-thumb{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 2rem;
    height: 2rem;
    background: white;
    border: 0.5rem solid #ff8737;
    border-radius: 100%;
    cursor: pointer;
    transition: all 350ms ease;
}
.seek_slider::-webkit-slider-thumb:hover, .volume_slider::-webkit-slider-thumb:hover{
    opacity: 0.7;
}
.seek_slider{
    width: 60%;
}
.volume_slider{
    width: 30%;
}

.current-time, .total-duration{
    width: 6rem;
    padding: 1rem 0;
}

.rotate{
    animation: rotation 8s infinite linear;
}

#wave{
    height: 7rem;
}

.loader{
    height: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader .stroke{
    background: #f1f1f1;
    height: 100%;
    width: 1rem;
    border-radius: 5rem;
    margin: 0 0.5rem;
    animation: animate 1.4s linear infinite;
}

.stroke:nth-child(1){
    animation-delay: 0s;
}
.stroke:nth-child(2){
    animation-delay: 0.3s;
}
.stroke:nth-child(3){
    animation-delay: 0.6s;
}
.stroke:nth-child(4){
    animation-delay: 0.9s;
}
.stroke:nth-child(5){
    animation-delay: 0.6s;
}
.stroke:nth-child(6){
    animation-delay: 0.3s;
}
.stroke:nth-child(7){
    animation-delay: 0s;
}

.Music > footer{
    text-align: right;
    width: 100%;
    padding-right: 3.5rem;
}

@keyframes rotation{
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(359deg);
    }
}

@keyframes animate{
    50%{
        height: 20%;
        background: #42f4aa;
    }
    100%{
        height: 100%;
    }
}

@media only screen and (max-width: 960px){
    .Music{
        transform: scale(1);
        padding-bottom: 15rem;
    }

    .Music > h3{
        font-size: 2rem;
    }
}