.Shopping{
    background: var(--color-black);
    border-radius: 0.5rem;
    text-align: center;
    color: var(--color-black);
    width: 100vw;
    height: 100vh;
}

.Shopping > header{
    background: var(--color-black-variant);
    height: 10rem;
}

.Shopping > main > .header{
    margin: 5rem 0;
    display: flex;
    justify-content: row;
    align-items: center;
    justify-content: space-around;
}

.Shopping > main > .header > ul{
    list-style: none;
}

.submenu{
    position: relative;
    width: 20rem;
}

.submenu > span{
    color: var(--color-white);
}

.submenu #carrito {
    display: none;
}

.submenu:hover #carrito {
    display: block;
    position: absolute;
    right: -10rem;
    top: 100%;
    z-index: 1;
    background-color: white;
    border-radius: 0.5rem;
    padding: 20px;
    min-height: 400px;
    min-width: 300px;
}

.submenu #carrito > button{
    border: 0.1rem solid var(--color-danger);
    background: transparent;
    color: var(--color-black);
    font-size: 1.4rem;
    font-weight: 800;
    border-radius: 0.3rem;
    width: 100%;
    height: 4rem;
    margin: 2rem 0;
    text-align: center;
    cursor: pointer;
    transition: all 350ms ease;
}
.submenu #carrito > button:hover{
    color: var(--color-white);
    background: var(--color-danger);
}

#lista-carrito th{
    padding: 0 1rem;
}

#lista-carrito td{
    font-size: 1rem;
}

.agregar-carrito {
    margin: 10px 0;
}

.submenu > span{
    font-size: 4rem;
    transition: all 350ms ease;
    cursor: pointer;
}
.submenu > span:hover{
    opacity: 0.7;
}

.borrar-curso{
    background: var(--color-danger);
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    padding-top: 0.1rem;
    text-decoration: none;
    color: var(--color-white);
    font-weight: bold;
    transition: all 350ms ease;
    cursor: pointer;
}

.borrar-curso:hover{
    background: var(--color-danger-variant)
}

.encabezado {
    color: var(--color-white);
    margin-bottom: 5rem;
}

#lista-cursos{
    margin: 0 auto;
    width: 80%;
    height: 60vh;
    display: block;
    overflow: scroll;
}

.card{
    width: 25rem;
    height: 35rem;
    text-align: center;
    margin: 0 5rem;
    background: var(--color-black-variant);
    color: var(--color-white);
    border-radius: 0.5rem; 
    display: inline-block;
    vertical-align: middle; 
}

.card > img{
    width: 25rem;
    height: 15rem;
    border-radius: 0.5rem 0.5rem 0 0;
}

.info-card {
    padding: 1rem 2rem;
}

.info-card > p {
    font-size: 1.4rem;
}

.info-card > .precio {
    text-decoration: none;
    font-weight: 700;
    font-size: 2.2rem;
    margin-left: 18rem;
    display: inline;
    color: var(--color-white);
}

.info-card > .precio > span {
    text-decoration: line-through;
    display: inline;
    font-size: 1.6rem;
    margin-top: 1rem;
    font-weight: 300;
    color: rgb(130, 130, 130);
}

.info-card > button{
    background: var(--color-danger);
    color: var(--color-white);
    font-size: 1.4rem;
    font-weight: 800;
    width: 100%;
    height: 4rem;
    border-radius: 0.3rem;
    margin: 1rem auto;
    text-align: center;
    cursor: pointer;
    transition: all 350ms ease;
}
.info-card > button:hover{
    background: var(--color-danger-variant);
}

@media only screen and (max-width: 960px){
    .Shopping{
        padding: 0;
    }

    .Shopping > main > .header{
        margin: 2rem 0;
        padding: 0;
    }  

    .Shopping > main > .header > ul{
        text-align: right;
    }

    .encabezado {
        display: none;
    }

    .submenu{
        position: relative;
        width: 100%;
    }

    .card{
        margin: 5rem auto;
    }
}