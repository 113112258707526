.WorksE{
    margin: 0 3.5rem 0 21.5rem;
    text-align: left;
}

.WorksE > main{
    height: 100vh;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
}

.WorksE > main > section{
    height: 100vh;
    padding-top: 12rem;
    scroll-snap-align: start;
}

.WorksE > main > section > img{
    position: absolute;
    top: 15%;
    left: 30%;
    filter: grayscale(0) blur(3px);
    opacity: 0.2;
    z-index: -1;
    -webkit-animation: slide-scale-ee 750ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-scale-ee 750ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.WorksE > main > section > .top{
    margin: 2.5rem 0 7.5rem 0;
}

.WorksE > main > section > .top > div{
    display: flex;
    justify-content: left;
    align-items: center;
}

.WorksE > main > section > .top > div > h4{
    flex: 2;
    font-size: 2rem;
    letter-spacing: 0.5rem;
}

.WorksE > main > section > .top > div > .top-d{
    flex: 2;
}

.WorksE > main > section > .top > div > .top-o{
    flex: 5;
}

.WorksE > main > section > .top > div > p{
    flex: 2;
    font-size: 1.4rem;
    color: var(--color-white-variant);
}

.WorksE > main > section > .bottom{
    display: flex;
    align-items: flex-start;
}

.WorksE > main > section > .bottom > .container-t{
    flex: 1;
    display: flex;
    align-items: center;
}

.WorksE > main > section > .bottom > .container-t > .img-b-b{
    visibility: hidden;
    position: relative;
    left: -30rem;
    transition: all 750ms ease;
}

.WorksE > main > section > .bottom > .container-t:hover > .img-b-b{
    visibility: visible;
    left: 0;
}

.WorksE > main > section > .bottom > .container-t > img{
    flex: 1;
    margin-right: 5rem;
    height: 25rem;
    width: 25rem;
    border-radius: 50%;
}

.WorksE > main > section > .bottom > .container-b{
    flex: 1;
}

.WorksE > main > section > .bottom > .container-b > div > h4{
    flex: 1;
    font-size: 2rem;
    letter-spacing: 0.5rem;
}

.WorksE > main > section > .bottom > .container-b > div > div > a > img{
    width: 6rem;
    height: 6rem;
    margin: 0.6rem;
    padding: 0.5rem;
    border-radius: 5px;
    transition: all 350ms ease;
}
.WorksE > main > section > .bottom > .container-b > div > div > a > img:hover{
    opacity: 0.7;
    transform: scale(1.3);
}

.WorksE > main > section > .bottom > .container-b > div > div > a > ion-icon{
    margin: 1rem 2rem 0 1rem;
    transition: all  350ms ease;
}
.WorksE > main > section > .bottom > .container-b > div > div > a > ion-icon:hover{
    opacity: 0.7;
    transform: scale(1.3);
}

.WorksE > main > .four > .container{
    margin: 3rem 0;
    height: 500px;
    overflow: scroll;
}

.WorksE > main > .four > .container > .c-info{
    border-left: 1px solid var(--color-white);
    border-right: 1px solid var(--color-white);
    margin: 0 1rem 1.5rem 1rem;
    padding-left: 1rem;
    text-align: left;
    cursor: pointer;
    transition: all 150ms ease;
}
.WorksE > main > .four > .container > .c-info:hover{
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    background: rgba(100, 100, 100, 0.3);
    border-radius: 0.2rem;
}

.WorksE > main > .four > .container > .c-info > a > h4{
    color: var(--color-white);
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    font-weight: 500;
}

.WorksE > main > .four > .container > .c-info > a > i{
    color: var(--color-white);
    font-size: 1rem;
    font-weight: 200;
    opacity: 0.7;
}

.WorksE > main > .four > .container > .c-info > a > p{
    color: var(--color-white);
    font-size: 1rem;
    font-weight: 200;
}

@-webkit-keyframes slide-scale-ee{
    0% {
        -webkit-transform: translateX(25.5rem) translateY(8rem) scale(0.4);
        transform: translateX(25.5rem) translateY(8rem) scale(0.4);
    }
    100% {
        -webkit-transform: translateX(0) translateY(0) scale(1);
        transform: translateX(0) translateY(0) scale(1);
    }
}
@keyframes slide-scale-ee{
    0% {
        -webkit-transform: translateX(25.5rem) translateY(8rem) scale(0.4);
        transform: translateX(25.5rem) translateY(8rem) scale(0.4);
    }
    100% {
        -webkit-transform: translateX(0) translateY(0) scale(1);
        transform: translateX(0) translateY(0) scale(1);
    }
}

@media only screen and (max-width: 960px){
    .WorksE{
        margin: 0;
        padding: 0 1.5rem 1.5rem 1.5rem;
    }

    .WorksE > main > .two, .WorksE > main > .three{
        padding-top: 15rem;
    }

    .WorksE > main > section > img{
        width: 80%;
        top: 30%;
        left: 10%;
        opacity: 0.15;
    }

    .WorksE > main > section > h3{
        font-size: 2rem;
    }

    .WorksE > main > section > .top{
        margin: 2.5rem 0;
    }
    
    .WorksE > main > section > .top > div > h4{
        font-size: 1rem;
    }

    .WorksE > main > section > .top > div > p{
        font-size: 0.8rem;
    }

    .WorksE > main > section > .bottom{
        flex-direction: column;
    }
    
    .WorksE > main > section > .bottom > .container-t > .img-b-b{
        left: -16rem;
    }
    
    .WorksE > main > section > .bottom > .container-t:hover > .img-b-b{
        left: 4rem;
    }
    
    .WorksE > main > section > .bottom > .container-t > img{
        height: 32vw;
        width: 32vw;
        margin-bottom: 3rem;
    }
    
    .WorksE > main > section > .bottom > .container-b > div > h4{
        font-size: 1rem;
    }

    .WorksE > main > section > .bottom > .container-b > div > div > a > img{
        width: 3rem;
        height: 3rem;
        padding: 0;
    }

    .WorksE > main > .four{
        padding-top: 8rem;
    }

    .WorksE > main > .four > h3{
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }

    .WorksE > main > .four > .container{
        height: 450px;
    }

    .WorksE > main > .four > .container > .c-info > a > h4{
        font-size: 1rem;
    }
    
    .WorksE > main > .four > .container > .c-info > a > i{
        display: none;
    }
    
    .WorksE > main > .four > .container > .c-info > a > p{
        font-size: 0.8rem;
    }
}